<script>
import appConfig from "@/app.config";
import {mapGetters} from 'vuex'


export default {
  page: {
    title: "Billing Empty",
    meta: [{ name: "description", content: appConfig.description }]
  },

  async created() {
    if(this.allTagihan.length > 0){
      this.$router.push({name : 'payment', query : {appRoute : this.$route.query.appRoute}});
    }
    // window.console.log(this.billingExists);
  },
  computed : {
    ...mapGetters({
        allTagihan : 'getAllTagihan',
    }),
  }
}
</script>
<template>
  <div class="account-pages my-5 pt-5" style="overflow: hidden;">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <h4 class="display-2 fw-medium">
              <!-- 4<i class="bx bx-buoy bx-spin text-primary display-3"></i>4 -->
            <img src="@/assets/images/billing-empty.png" alt class="img-fluid" style="max-width: 60%;"/>
            </h4>
            <h4 class="text-uppercase">Tagihan Tidak Ditemukan</h4>
            <div class="mt-1 text-center">
                <p>
                    Untuk saat ini, tidak ada tagihan<br>yang harus dibayar.
                    <br><br>
                    Kemungkinan tagihanmu sudah lunas<br>atau belum ada tagihan yang diterbitkan.
                    <br>
                    Coba cek lagi secara berkala ya...
                </p>
              <!-- <router-link tag="a" class="btn btn-primary" style="width: 100%" to="/">Back to Payment</router-link> -->
            </div>
            <!-- <div class="mt-1 text-center">
                <router-link class="btn btn-primary" :to="{name : 'payment', query : {appRoute : this.$route.query.appRoute}}">Back To Payment</router-link>
            </div> -->
          </div>
        </div>
      </div>
      <!-- <div class="row justify-content-center">
        <div class="col-md-8 col-xl-6">
          <div>
            <img src="@/assets/images/error-img.png" alt class="img-fluid" />
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<style scoped>
p{
    padding: 10px;
}
</style>

